export default {
    data(){
        return {
            loading: false,
            newPwd: '',
            oldPwd: '',
            confirmPwd: '',
        }
    },
    methods: {
        updatePwd: function(){
            var clientId = this.$store.getters.clientId
            if(!this.oldPwd.trim()) {
                this._toast.warning(this, '請輸入當前密碼')
                return
            }
            if(!this.newPwd.trim()) {
                this._toast.warning(this, '請輸入新密碼')
                return
            }
            if(this.newPwd.trim().length < 6) {
                this._toast.warning(this, '密碼長度小於6位')
                return
            }
            if(this.confirmPwd.trim() != this.newPwd.trim()) {
                this._toast.warning(this, '兩次新密碼輸入不一致')
                return
            }
            this.loading = true
            this.$store.dispatch('updatePassword', {
                clientId,
                newPwd: this.newPwd,
                oldPwd: this.oldPwd
            }).then(() => {
                this._toast.success(this, '密碼修改成功')
                this.loading = false
                this.newPwd = ''
                this.oldPwd = ''
                this.confirmPwd = ''
            }).catch((error) => {
                this._toast.warning(this, error || '操作失敗')
                this.loading = false
            })
        }
    },
}