<template>
	<div id='update-password'>
		<div class="form-item">
			<div class="hint-w">
				<label class="hint" for="oldpwd">當前密碼:</label>
			</div>
			<input type="password" id="oldpwd" placeholder="請輸入當前密碼" v-model="oldPwd">
		</div>
		<div class="form-item">
			<div class="hint-w">
				<label class="hint" for="newpwd">新密碼:</label>
			</div>
			<input type="password" id="newpwd" placeholder="請輸入新密碼" v-model="newPwd">
		</div>
		<div class="form-item">
			<div class="hint-w">
				<label class="hint" for="confirm-newpwd">確認新密碼:</label>
			</div>
			<input type="password" id="confirm-newpwd" placeholder="請再輸入一次新密碼" v-model="confirmPwd">
		</div>
		<div class="btn-update-pwd" @click="updatePwd"><div class="lds-ring1" v-if="loading"><div></div><div></div><div></div><div></div></div>{{loading ? '正在提交...' : '保存修改'}}</div>
	</div>
</template>

<script>
import update_pwd from '@/views/mixins/update_pwd'
	export default {
		mixins: [
			update_pwd
		]
	};
</script>

<style lang="scss" scoped>

	#update-password {
		display: flex;
		flex-direction: column;
		padding-left: 15px;
	}
	
	.form-item {
		display: flex;
		flex-direction: row;
		margin-bottom: 20px;
		position: relative;
	}

	.form-item:nth-child(1) {
		margin-top: 20px;
	}

	.form-item > .hint-w {
		display: inline-block;
		width: 77px;
		height: 36px;
		line-height: 36px;
		text-align: right;
		font-weight: 400;
		color: #636363;
		font-size: 16px;
		margin-right: 16px;
		white-space: nowrap;
	}

	.form-item > .hint-w > .hint.require {
		position: relative;
	}

	.form-item > .hint-w > .hint.require::before {
		content: '*';
		position: absolute;
		left: -9px;
		top: -9px;
		color: #ee1b23;
		font-weight: bold;
		font-size: 16px;
	}


	.form-item > input {
		height: 36px;
		line-height: 36px;
		border: 1px solid #D6E7EB;
		box-sizing: border-box;
		font-weight: bold;
		font-size: 16px;
		color: #231F20;
		width: 454px;
		background-color: transparent;
		padding-left: 16px;
	}

	.form-item > input#idcard {
		width: 256px;
	}

	.form-item > #birthday {
		position: relative;
	}

	.form-item > #birthday::-webkit-calendar-picker-indicator {
		content: '';
		position: absolute;
		right: 3px;
		top: 3px;
		height: 22px;
		width: 22px;
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-image: url(../../../../assets/imgs/pc/user/img_rili@2x.png);
		z-index: 10;
	}

	.form-item > .icon {
		position: absolute;
		left: 511px;
		width: 36px;
		height: 34px;
		border-left: 1px solid #D6E7EB;
	}

	.form-item > .icon::after {
		content: '';
		position: absolute;
		left: 6px;
		top: 6px;
		height: 22px;
		width: 22px;
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-image: url(../../../../assets/imgs/pc/user/img_rili@2x.png);
	}

	.form-item > #birthday:before {
		content: '';
		position: absolute;
		right: 0px;
		top: 0px;
		height: 36px;
		width: 34px;
		border-left: 1px solid #D6E7EB;
	}

	.form-item > input::placeholder {
		font-size: 16px;
		font-weight: 400;
		color: #969696;
	}

	.form-item > .checkbox {
		display: inline-block;
		height: 36px;
		line-height: 36px;
		position: relative;
		padding-left: 24px;
		color: #969696;
		font-size: 16px;
		font-weight: 400;
		margin-left: 16px;
		margin-right: 16px;
		cursor: pointer;
	}

	.form-item > .checkbox::after {
		content: '';
		position: absolute;
		width: 16px;
		height: 16px;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		background-image: url(../../../../assets/imgs/pc/user/img_huichange@2x.png);
		background-size: 100% 100%;
		background-repeat: no-repeat;
	}

	.form-item > .checkbox.checked::after {
		background-image: url(../../../../assets/imgs/pc/user/img_change@2x.png);
	}

	.form-item > .idcard-type {
		height: 36px;
		line-height: 36px;
		border: 1px solid #D6E7EB;
		box-sizing: border-box;
		margin-right: 16px;
		width: 182px;
		padding-left: 16px;
		cursor: pointer;
		position: relative;
	}

	.form-item > .idcard-type::after {
		content: '';
		position: absolute;
		right: 0;
		top: 0;
		width: 36px;
		height: 34px;
		box-sizing: border-box;
		border-left: 1px solid #D6E7EB;
	}

	.form-item > .idcard-type::before {
		content: '';
		position: absolute;
		right: 12px;
		top: 50%;
		transform: translateY(-50%);
		width: 12px;
		height: 6px;
		background-image: url(../../../../assets/imgs/pc/user/img_xiala@2x.png);
		background-size: 100% 100%;
		background-repeat: no-repeat;
	}

	.btn-save {
		width: 188px;
		height: 42px;
		border-radius: 15px;
		color: #fff;
		background-color: #36C4D0;
		line-height: 42px;
		text-align: center;
		font-size: 20px;
		font-weight: bold;
		cursor: pointer;
		margin-left: 197px;
	}

	.btn-update-pwd {
		width: 188px;
		height: 42px;
		border-radius: 15px;
		color: #fff;
		background-color: #36C4D0;
		line-height: 42px;
		text-align: center;
		font-size: 20px;
		font-weight: bold;
		cursor: pointer;
		margin-left: 197px;
		margin-top: 60px;
		margin-bottom: 156px;
	}


    /** loading start */
    .lds-ring1 {
        display: inline-block;
        position: relative;
        width: 32px;
        height: 32px;
    }
    
    .lds-ring1 div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 24px;
        height: 24px;
        margin: 4px;
        border: 4px solid #fff;
        border-radius: 50%;
        animation: lds-ring1 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #fff transparent transparent transparent;
    }
    
    .lds-ring1 div:nth-child(1) {
        animation-delay: -0.45s;
    }
    
    .lds-ring1 div:nth-child(2) {
        animation-delay: -0.3s;
    }
    
    .lds-ring1 div:nth-child(3) {
        animation-delay: -0.15s;
    }

    @keyframes lds-ring1 {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    /** loading end */ 
</style>
